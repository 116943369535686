//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000; // The default text color

$primary:    #29265e;
$secondary:  #6c757d;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
$light:      #f8f9fa;
$dark:       #212529;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

$white: #fff;

$offcanvas-horizontal-width: auto;
$offcanvas-border-width: 10px;
$offcanvas-border-color: white;

$offcanvas-color: $white;
$offcanvas-bg-color: $primary;

$offcanvas-padding-y: 20px;
$offcanvas-padding-x: 50px;

$btn-padding-x: 22px;

$border-radius:0;
$border-radius-sm:0;
$border-radius-lg:0;
$font-size-base: 1.2rem;
$h4-font-size: 1.6rem;
