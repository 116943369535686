@import "variables";
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/utilities';
@import "bootstrap";
@import "fonts";

#search_it-form {
  z-index: 999999999999;
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: $primary;
  padding-top: 12px;
  padding-bottom: 12px;
  &.open {
    display: flex;
  }
  fieldset {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
  .search_it-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      color: white;
    }
    .searchtext {
      padding: 4px;
      margin-right: 4px;
      margin-left:  4px;
    }
  }
  #closer {
    cursor: pointer;
    display: inline-flex;
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }
}

#main {
  padding-top: 60px;
  padding-bottom: 40px;
  /*
  padding-left: 15px;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    padding-left: 116px;
  }

   */
  h1 {
    text-transform: uppercase;
  }
  h1,h2,h3,h4,h5,p, ul {
    color: $primary;
  }
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: .875rem;
  font-weight: 700;
  height: auto;
  right: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000
}

#primary[tabindex="-1"]:focus {
  outline: 0
}

.skip-link {
  background-color: #24292e;
  color: #fff;
  padding: 10px 20px
}

.show-on-focus {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  top: 0;
  left: 0
}

.show-on-focus:focus {
  z-index: 100;
  width: auto;
  height: auto;
  clip: auto
}

.top-line {
  background: $primary;
  height: 50px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  .logo {
    text-decoration: none;
    align-items: center;
    img, svg {
      height: 36px;
      max-height: 100%;
    }
    h2 {
      font-size: 11px;
      text-decoration: none;
      color: $white;
      margin-left: 4px;
      margin: 0 0 0 5px;
    }
  }
}
#burger {
  border: none;
  background: $primary;
  top: 8px;
  right: 8px;
  svg {
    width: auto;
    height: 36px;
    display: inline-block;
    &:last-child {
      display: none;
    }
  }
  .open & {
    svg {
      display: none;
      &:last-child {
        display: inline-block;
      }
    }
  }
}
.offcanvas-body {
  margin-top: 60px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        color: $white;
        text-decoration: none;
        font-weight: 700;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        line-height: 34px;
        position: relative;
        svg {
          width: 28px;
          height: auto;
          max-height: 28px;
          margin-right: 6px;
        }
        &.active {
          text-decoration: underline;
          background: #121032;
        }
        &:focus {
          text-decoration: underline;
          background: #321016;
          color: white;
        }
        &:hover {
          color: #efd3a1;
          text-decoration: underline;
        }
        &:visited {
          color: #ffffff;
          text-decoration: none;
        }
      }
      ul {
        margin-left: 36px;
        li {
          a {
            font-weight: 300;
            line-height: 28px;
            padding-left: 8px;
            display: inline-block;
          }
        }
      }
    }
  }
}
.close_menu {
  padding: 0;
  margin: 0;
  background: transparent;
  right: 17px;
  top: 9px;
}
.close_menu svg {
  width: auto;
  height: 36px;
  display: inline-block;
}
.startslider {
  .slick-slide {
    margin: 0 5px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -5px;
  }
  min-height: 100px;
  a {
    text-decoration: none;
    color: $primary;
  }
  .slick-prev, .slick-next {
    position: absolute;
    z-index: 99;
    top: 20%;
    left: 0;
    @media (min-width: 768px) {
      left: -30px;
    }
    svg {
      width: 20px;
      height: 20px;
      @media (min-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .slick-next {
    left: auto;
    right: 0;
    @media (min-width: 768px) {
      right: -30px;
    }
  }
  h4 {
    font-weight: 700;
  }
}
.imageslider {
  a {
    text-decoration: none;
  }
  .slick-prev, .slick-next {
    position: absolute;
    z-index: 99;
    top: 20%;
    left: 0;
    @media (min-width: 768px) {
      left: -30px;
    }
    svg {
      width: 20px;
      height: 20px;
      @media (min-width: 768px) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .slick-next {
    left: auto;
    right: 0;
    @media (min-width: 768px) {
      right: -30px;
    }
  }
  .slick-slide {
    > div {
      display: flex;
      justify-content: center;
      img {
        width: auto !important;
      }
    }
  }
}
.pt-5 {
  padding-top: 1.5rem !important;
}
.pt-6 {
  padding-top: 2rem !important;
}
.pt-7 {
  padding-top: 2.5rem !important;
}
.pt-8 {
  padding-top: 3rem !important;
}
.pt-14 {
  padding-top: 6rem !important;
}
.linker {
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-weight: 700;
  svg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.textbox {
  a {
    text-decoration: underline;
  }
  ul {
    margin: 10px 0 10px 0;
    padding: 0 0 0 20px;
  }
}
#footer {
  margin-top: 50px;
  padding-left: 15px;
  @media (min-width: 768px) {
    padding-left: 116px;
  }
  background-color: $primary;
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) {
    flex-direction: row;
  }
  align-items: center;
  p {
    color: $white;
    margin: 0;
    padding-right: 10px;
    font-size: 16px;
  }
  ul {
    display: flex;
    flex-direction: column;
    @media (min-width: 500px) {
      flex-direction: row;
    }
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        font-size: 16px;
        text-decoration: none;
        color: $white;
        padding-right: 10px;
      }
    }
  }
}
.download {
  display: block;
  text-decoration: none;
  padding: 15px;
  margin-bottom: 15px;
  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 668px) {
      flex-direction: row;
    }
    div {
      padding-top: 20px;
      @media (min-width: 668px) {
        padding-top: 0;
      }
      padding-left: 10px;
      padding-right: 10px;
      overflow: hidden;
      p {
        margin-bottom: 3px;

      }
    }
  }
  width: 100%;
  border: 2px solid $primary;
  .btn {
    white-space: nowrap;
    margin-right: 20px;
    svg {
      width: 16px;
      height: auto;
      margin-right: 10px;
    }
  }
}
#main .catlist {
  .item {

    a {
      text-decoration: none;
      display: block;
      height: 100%;

      h3 {
        background-color: $primary;
        font-size: 22px;
        color: $white;
        padding: 4px 5px 4px 10px;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      h4 {
        padding: 2px 5px 4px 10px;
        font-size: 18px;
      }
      h5 {
        padding: 2px 5px 4px 10px;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
.articlelist {
  a, .item {
    text-decoration: none;
    display: block;
    height: 100%;
    position: relative;
    img {
      &.bordered {
        border: 2px solid $primary;
      }
    }
    p {
      margin-bottom: 3px;
    }
    h5 {
      font-weight: 600;
      font-size: 20px
    }
    .btn {
      margin-top: 3px;
      svg {
        width: 16px;
        height: auto;
        margin-right: 10px;
      }
    }
    &.hasborder {
      border: 2px solid $primary;
      padding-bottom: 40px;
      strong {
        display: none;
      }
      .btn {
        position: absolute;
        bottom: 15px;
        left: 15px;
        width: calc(100% - 30px);
      }
    }
  }
}
.date {
  border: 2px solid #29265e;
  padding: 15px;
  display: block;
  text-decoration: none;
  position: relative;
  h4 {
    font-weight: 700;
    font-size: 20px;
    svg {
      width: 16px;
      height: auto;
      margin-right: 10px;
    }
  }
  .btn {
    position: absolute;
    bottom: 12px;
    right: 15px;
    svg {
      width: 16px;
      height: auto;
      margin-right: 10px;
    }
  }
}
.form-group {
  margin-bottom: 15px;
  label {
    color: $primary;
    font-weight: 700;
  }
}
.rex-yform {
  .btn {
    display: block;
    width: 100%;
  }
}
body {
  opacity: .01;
  opacity: 1;
  @include transition(opacity 0s .1);
  &.loaded {
    opacity: 1;
  }
}

.slick-slider
{
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.langswitch {
  top: 0;
  right: 80px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    a {
      position: relative;
      border: 1px solid white;
      border-radius: 3px;
      opacity: .7;
      overflow: hidden;
      display: inline-block;
      width: 40px;
      height: 30px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: opacity ease-in-out .3s;
      &:hover {
        opacity: .95;
      }
      span {
        position: absolute;
        left: -40px;
      }
      &.lang-de {

      }
      &.lang-en {

      }
    }
    &:first-child {
      a {
        margin-right: 4px;
      }
    }
    &.active {
      a {
        opacity: 1;
      }
    }
  }
}
.sstrong {
  font-weight: 700;
  color: $primary;
}
form[action*="clang=2"] .translate-btn{
  display: none;
}
